import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';

const GraphComponent = ({ dates, assetReturns, strategyReturns }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Clear the canvas before drawing a new chart
      ctx.clearRect(0, 0, chartRef.current.width, chartRef.current.height);

      // Destroy existing chart if it exists
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }

      // Grouping dates by month
      const datesByMonth = dates.reduce((acc, date) => {
        const monthYear = new Date(date).toLocaleString('en-us', { month: 'short', year: 'numeric' });
        acc[monthYear] = acc[monthYear] || [];
        acc[monthYear].push(date);
        return acc;
      }, {});

      // Get the earliest and latest dates to determine the range
      const earliestDate = new Date(Math.min(...dates.map(date => new Date(date))));
      const latestDate = new Date(Math.max(...dates.map(date => new Date(date))));

      // Generate all months between the earliest and latest date
      const labels = [];
      let currentMonth = earliestDate;
      while (currentMonth <= latestDate) {
        labels.push(currentMonth.toLocaleString('en-us', { month: 'short', year: 'numeric' }));
        currentMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
      }

      // Create datasets
      const assetReturnsByMonth = labels.map(month => {
        const datesOfMonth = datesByMonth[month] || [];
        if (datesOfMonth.length === 0) return 0;
        const sum = datesOfMonth.reduce((sum, date) => {
          const index = dates.indexOf(date);
          return sum + assetReturns[index];
        }, 0);
        return sum / datesOfMonth.length; // Average for the month
      });

      const strategyReturnsByMonth = labels.map(month => {
        const datesOfMonth = datesByMonth[month] || [];
        if (datesOfMonth.length === 0) return 0;
        const sum = datesOfMonth.reduce((sum, date) => {
          const index = dates.indexOf(date);
          return sum + strategyReturns[index];
        }, 0);
        return sum / datesOfMonth.length; // Average for the month
      });

      chartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: 'Asset Return',
              data: assetReturnsByMonth,
              borderColor: '#26a9e0',
              pointRadius: 0,
            },
            {
              label: 'Strategy Return',
              data: strategyReturnsByMonth,
              borderColor: '#f6921e',
              pointRadius: 0,
            },
          ],
        },
        options: {
          maintainAspectRatio: false, // Allow the chart to resize freely
          scales: {
            x: {
              type: 'category',
              grid: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)', // Color of grid lines
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: true,
                color: 'rgba(0, 0, 0, 0.1)', // Color of grid lines
              },
            },
          },
        },
      });
    }
  }, [dates, assetReturns, strategyReturns]);

  return (
    <div style={{ position: 'relative', width: '100%', height: '400px' }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default GraphComponent;
