import React, { useState } from 'react';
import logo from './assets/img/logo.png';
import './App.css'
import GraphComponent from './GraphComponent';


// ResultsDisplay is a functional component that manages state for form data, API results, loading status, and errors.
function ResultsDisplay() {
    // useState hooks to manage state within the functional component
    const [formData, setFormData] = useState({
        symbol: 'KO',           // Default values for form fields
        start_date: '2023-01-15',
        end_date: '2024-03-26',
        initial_capital: 1000,		
		sequence_to_find: [3, 6, 9],
        beta:1000000,
    });
    const [result, setResult] = useState(null);  // Stores the API response data
    const [loading, setLoading] = useState(false);  // Tracks whether the request is loading
    const [error, setError] = useState(null);  // Stores any error from the API request

    // handleChange updates the formData state when input fields change
    const handleChange = (e) => {
        const { name, value } = e.target;  // Extract name and value from the event target (input fields)
        setFormData(prevState => ({
            ...prevState,   // Spread the previous state to retain existing field values
            [name]: value   // Update the value of the field that was changed
        }));
    };




    /*const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === "sequence_to_find") {
            const cleanedNumbers = value.split(',')
                .map(num => parseInt(num.trim(), 10))
                .filter(num => !isNaN(num))  // Keeps only valid numbers
                .map(num => isNaN(num) ? 0 : num);  // Convert NaN to 0
    
            setFormData(prevState => ({
                ...prevState,
                [name]: cleanedNumbers
            }));
        }
    };*/
	
	const handleBlur = (e) => {
		//console.log("sdf");
    const { name, value } = e.target;
	if (name === "symbol") {
        setResult();
		 setError();
    }
	
    if (name === "sequence_to_find") {
        const cleanedNumbers = value.split(',')
            .map(num => parseInt(num.trim(), 10))
            .filter(num => !isNaN(num));  // Filters out NaN values
    
        setFormData(prevState => ({
            ...prevState,
            [name]: cleanedNumbers
        }));
    }
};
    


    
    /*const handleChangeArray = (e) => {
    const { name, value } = e.target;

    // Split the string by commas, trim each item, and map each element to an integer
    const arrayValue = value
        .split(',') // Split by commas
        .map(item => item.trim()) // Trim each item
        .filter(item => item !== '') // Filter out empty strings
        .map(item => parseInt(item, 10)); // Convert each item to an integer

    setFormData(prevState => ({
        ...prevState,
        [name]: arrayValue
    }));
};*/

const handleChangeArray = (e) => {
    const { name, value } = e.target;

    // Split the string by commas, trim each item, and map each element to an integer
    const arrayValue = value
        .split(',') // Split by commas
        .map(item => item.trim()) // Trim each item
        .filter(item => item !== '') // Filter out empty strings
        .map(item => {
            const parsedItem = parseInt(item, 10);
            return isNaN(parsedItem) ? null : parsedItem; // Return null for NaN values
        });

    setFormData(prevState => ({
        ...prevState,
        [name]: arrayValue
    }));
};
    
    // handleSubmit is called when the form is submitted
    const handleSubmit = (e) => {
        e.preventDefault();  // Prevent default form submission behavior
        setLoading(true);    // Set loading to true to show a loading indicator
        console.log(JSON.stringify(formData))
        // Fetch API call
        //  fetch('http://139.162.249.112:5000/process', {                      
        fetch('https://utcompass.com/process', {             
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',  // Set content type to JSON
            },
            body: JSON.stringify(formData)  // Convert formData object to JSON string
        })
            .then(response => response.json())  // Parse JSON response to JavaScript object
            .then(data => {
                setResult(data);  // Store the API response data in result state
                setLoading(false); // Set loading to false after data is received
            })
            .catch((error) => {
                console.error('Error:', error); // Log any errors to the console
                setError(error);  // Store the error
			     
                setLoading(false); // Set loading to false if an error occurs
            });
    };
	
	 const [inputValue, setInputValue] = useState('KO');

     const handleClick = (value) => {
		  setResult();
		  setError();
        setInputValue(value);
        setFormData(prevState => ({
            ...prevState,
            symbol: value
        }));
        console.log(JSON.stringify(formData) )
    };

    // Render the form and results
    return (
        <div className='trading_view'>
            <div className='logo'>
                <img src={logo} alt="logo" />
            </div>
            <div className='trading_result'>
                <form onSubmit={handleSubmit} className='trading_form'>
                    {/* Input fields for symbol, start date, end date, and beta */}
                    <label>
                        Ticker
                        <input type="text" name="symbol" value={formData.symbol} onChange={handleChange} className='c_blue' onBlur={handleBlur} onKeyDown={handleBlur} required/>
                    </label>
                    <label>
                        Start Date
                        <input type="date" name="start_date" value={formData.start_date} onChange={handleChange} />
                    </label>
                    <label>
                        End Date
                        <input type="date" name="end_date" value={formData.end_date} onChange={handleChange} />
                    </label>
                    <label>
                        Initial Capital
                        <input type="number" name="initial_capital" value={formData.initial_capital} onChange={handleChange} />
                    </label>
					<label>
                        Sequence
                        <input type="text" name="sequence_to_find" value={formData.sequence_to_find} onChange={handleChangeArray} />
                    </label>
					
                    <button type="submit">Submit</button>  {/* Submit button for the form */}
                </form>

                {/* Conditional rendering for loading indicator, error message, and results */}
                {loading && <p className='loading'>Loading...</p>}
                {error && <p>Error: Please enter information correctly</p>}
                {result && (
				
				  
                    // Assuming the data has been fetched and is stored in 'result'
                    <div className='data_row'>
                        <div className='left_column column'>
                            <h3 className='c_orange'>{`369 Trading Strategy Performance on`} <span className='c_blue'>{formData.symbol}</span> {`with ${result.number_of_trades} Trades`}</h3>
                            <p><span>{`369 Optimal Final Capital:`}</span> <span className='c_orange'>${result.final_capital.toFixed(2)}</span></p>
                            <p><span>{`369 Optimal Holding Period: `}</span> <span className='c_orange'>{result.holding_period}</span></p>
                            <p><span>{`369 Optimal Sharpe Ratio:`}</span> <span className='c_orange'>{result.sharpe_ratio.toFixed(2)}</span></p>
                            <p><span>{`369 Optimal Maximum Drawdown:`}</span> <span className='c_orange'>{(result.maximum_drawdown * 100).toFixed(2)}</span></p>
                            <p><span>{`369 Optimal Number of Trades:`}</span> <span className='c_orange'>{result.number_of_trades}</span></p>
                        </div>
                        <div className='right_column column'>
                            <h3 className='c_blue'>Buy And Hold For Ever Strategy</h3>
                            <p><span>{`Asset ${formData.symbol} Final Capital:`}</span> <span className='c_blue'>${result.asset_final_capital.toFixed(2)}</span></p>
                            <p><span>{`Asset ${formData.symbol} Sharpe Ratio:`}</span> <span className='c_blue'>{result.asset_sharpe_ratio.toFixed(2)}</span></p>
                            <p><span>{`Asset ${formData.symbol} Maximum DrawDown:`}</span> <span className='c_blue'>{(result.asset_max_drawdown * 100).toFixed(2)}</span></p>
                        </div>
						
						 <GraphComponent
        dates={result.graph_data.dates}
        assetReturns={result.graph_data.asset_returns}
        strategyReturns={result.graph_data.strategy_equity}
      />
	  
                    </div>
                )}
            </div>
            <div className='currency_column'>
                <h3>Click on Ticker below<br></br> Then click Submit</h3>
                <div className='currencies'>
                    <div className='currency'>
                        <h4>Indices</h4>
                        <div className='types'>
                            <div onClick={() => handleClick('^GSPC')}>
                                <h5>^GSPC</h5>
                                <small>S&P500</small>
                            </div>
                            <div onClick={() => handleClick('^FTSE')}>
                                <h5>^FTSE</h5>
                                <small>FTSE</small>
                            </div>
                            <div onClick={() => handleClick('^DJI')}>
                                <h5>^DJI</h5>
                                <small>Dow Jones</small>
                            </div>
                            <div onClick={() => handleClick('^IXIC')}>
                                <h5>^IXIC</h5>
                                <small>Nasdaq</small>
                            </div>
                            <div onClick={() => handleClick('^GDAXI')}>
                                <h5>^GDAXI</h5>
                                <small>Dax</small>
                            </div>
                            <div onClick={() => handleClick('^N225')}>
                                <h5>^N225</h5>
                                <small>Nikkei</small>
                            </div>
                            <div onClick={() => handleClick('^N100')}>
                                <h5>^N100</h5>
                                <small>Euronext</small>
                            </div>
                            <div onClick={() => handleClick('^HSI')}>
                                <h5>^HSI</h5>
                                <small>Hang Seng</small>
                            </div>
                        </div>
                    </div>
                    <div className='currency'>
                        <h4>Bonds</h4>
                        <div className='types'>
                            <div onClick={() => handleClick('^IRX')}>
                                <h5>^IRX</h5>
                                <small>13 Week Treasury Bill</small>
                            </div>
                            <div onClick={() => handleClick('^FVX')}>
                                <h5>^FVX</h5>
                                <small>5 Year Treasury Yield</small>
                            </div>
                            <div onClick={() => handleClick('^TNX')}>
                                <h5>^TNX</h5>
                                <small>Interest Rate 10 Year</small>
                            </div>
                            <div onClick={() => handleClick('^TYX')}>
                                <h5>^TYX</h5>
                                <small>30 Year Treasury Yield</small>
                            </div>
                        </div>
                    </div>
                    <div className='currency'>
                        <h4>Commodities</h4>
                        <div className='types'>
                            <div onClick={() => handleClick('CL=F')}>
                                <h5>CL=F</h5>
                                <small>Crude Oil</small>
                            </div>
                            <div onClick={() => handleClick('GC=F')}>
                                <h5>GC=F</h5>
                                <small>Gold</small>
                            </div>
                            <div onClick={() => handleClick('SI=F')}>
                                <h5>SI=F</h5>
                                <small>Silver</small>
                            </div>
                            <div onClick={() => handleClick('HG=F')}>
                                <h5>HG=F</h5>
                                <small>Copper</small>
                            </div>
                            <div onClick={() => handleClick('NG=F')}>
                                <h5>NG=F</h5>
                                <small>Natural Gas</small>
                            </div>
                            <div onClick={() => handleClick('BZ=F')}>
                                <h5>BZ=F</h5>
                                <small>Brent Crude Oil</small>
                            </div>
                            <div onClick={() => handleClick('ZC=F')}>
                                <h5>ZC=F</h5>
                                <small>Corn</small>
                            </div>
                            <div onClick={() => handleClick('ZS=F')}>
                                <h5>ZS=F</h5>
                                <small>Soybeans</small>
                            </div>
                        </div>
                    </div>
                    <div className='currency'>
                        <h4>Stocks</h4>
                        <div className='types'>
                            <div onClick={() => handleClick('AAPL')}>
                                <h5>AAPL</h5>
                                <small>Apple</small>
                            </div>
                            <div onClick={() => handleClick('GOOG')}>
                                <h5>GOOG</h5>
                                <small>Google</small>
                            </div>
                            <div onClick={() => handleClick('META')}>
                                <h5>META</h5>
                                <small>Meta</small>
                            </div>
                            <div onClick={() => handleClick('MSFT')}>
                                <h5>MSFT</h5>
                                <small>Microsoft</small>
                            </div>
                            <div onClick={() => handleClick('TSLA')}>
                                <h5>TSLA</h5>
                                <small>Tesla</small>
                            </div>
                            <div onClick={() => handleClick('BP')}>
                                <h5>BP</h5>
                                <small>BP</small>
                            </div>
                            <div onClick={() => handleClick('GE')}>
                                <h5>GE</h5>
                                <small>GE</small>
                            </div>
                            <div onClick={() => handleClick('VOD')}>
                                <h5>VOD</h5>
                                <small>Vodafone</small>
                            </div>
                        </div>
                    </div>
                    <div className='currency'>
                        <h4>Banks & Funds (USD)</h4>
                        <div className='types'>
                            <div onClick={() => handleClick('HSBC')}>
                                <h5>HSBC</h5>
                                <small>HSBC</small>
                            </div>
                            <div onClick={() => handleClick('LYG')}>
                                <h5>LYG</h5>
                                <small>Lloyds</small>
                            </div>
                            <div onClick={() => handleClick('BCS')}>
                                <h5>BCS</h5>
                                <small>Barclays</small>
                            </div>
                            <div onClick={() => handleClick('SAN')}>
                                <h5>SAN</h5>
                                <small>Santander</small>
                            </div>
                            <div onClick={() => handleClick('JPM')}>
                                <h5>JPM</h5>
                                <small>JPMorgan</small>
                            </div>
                            <div onClick={() => handleClick('GS')}>
                                <h5>GS</h5>
                                <small>Goldman Sachs</small>
                            </div>
                            <div onClick={() => handleClick('BLK')}>
                                <h5>BLK</h5>
                                <small>Blackrock</small>
                            </div>
                            <div onClick={() => handleClick('SFTBY')}>
                                <h5>SFTBY</h5>
                                <small>Softbank</small>
                            </div>
                        </div>
                    </div>
                    <div className='currency'>
                        <h4>Currencies</h4>
                        <div className='types'>
                            <div onClick={() => handleClick('EURUSD=X')}>
                                <h5>EURUSD=X</h5>
                                <small>EUR/USD</small>
                            </div>
                            <div onClick={() => handleClick('JPY=X')}>
                                <h5>JPY=X</h5>
                                <small>USD/JPY</small>
                            </div>
                            <div onClick={() => handleClick('GBP=X')}>
                                <h5>GBP=X</h5>
                                <small>USD/GBP</small>
                            </div>
                            <div onClick={() => handleClick('AUD=X')}>
                                <h5>AUD=X</h5>
                                <small>USD/AUD</small>
                            </div>
                            <div onClick={() => handleClick('CAD=X')}>
                                <h5>CAD=X</h5>
                                <small>USD/CAD</small>
                            </div>
                            <div onClick={() => handleClick('MXN=X')}>
                                <h5>MXN=X</h5>
                                <small>USD/MXN</small>
                            </div>
                            <div onClick={() => handleClick('EURGBP=X')}>
                                <h5>EURGBP=X</h5>
                                <small>EUR/GBP</small>
                            </div>
                            <div onClick={() => handleClick('CNY=X')}>
                                <h5>CNY=X</h5>
                                <small>USD/CNY</small>
                            </div>
                        </div>
                    </div>
                    <div className='currency'>
                        <h4>Crypto</h4>
                        <div className='types'>
                            <div onClick={() => handleClick('BTC-USD')}>
                                <h5>BTC-USD</h5>
                                <small>Bitcoin USD</small>
                            </div>
                            <div onClick={() => handleClick('ETH-USD')}>
                                <h5>ETH-USD</h5>
                                <small>Ethereum USD</small>
                            </div>
                            <div onClick={() => handleClick('USDT-USD')}>
                                <h5>USDT-USD</h5>
                                <small>Tether USD</small>
                            </div>
                            <div onClick={() => handleClick('SOL-USD')}>
                                <h5>SOL-USD</h5>
                                <small>Solana USD</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResultsDisplay;