import React from 'react';
import ResultsDisplay from './ResultsDisplay';  // Import the ResultsDisplay component

// App component that serves as the root of your application
function App() {
  return (
    <div className="App">
      <ResultsDisplay />  
    </div>
  );
}

export default App;  // Export App component for use in index.js
